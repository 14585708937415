import shuffle from '../shuffle'
/*
Add: questions to match molecule names to # of carbons
questions to match protons, electrons, water, oxygen, to name and diagram
*/
const carbonMatchY = [ 280, 220, 160, 100]
const carbonsMatch = [
  { 
    type: "process",
    x: 580,
    y: 50,
    text: 'Drag these:',
  },
{ 
    type: "carbon",
    x: 580,
    y: 280,
    c: 6,
    name: 'glucose',
    initialX: 580,
    initialY: 280,
    width: 132,
    height: 24,
    isDragging: false,
  },
  { 
    type: "carbon",
    x: 580,
    y: 220,
    c: 3,
    name: 'pyruvate',
    initialX: 580,
    initialY: 220,
    width: 60,
    height: 24,
    isDragging: false,
  },
  { 
    type: "carbon",
    x: 580,
    y: 160,
    c: 2,
    name: 'acetyl',
    initialX: 580,
    initialY: 160,
    width: 36,
    height: 24,
    isDragging: false,
  },
  { 
    type: "carbon",
    x: 580,
    y: 100,
    c: 1,
    name: 'carbonDioxide',
    initialX: 580,
    initialY: 100,
    width: 12,
    height: 24,
    isDragging: false,
  },
];

const shuffleCarbons = () => {
  const positions = shuffle(carbonMatchY);
  let newArray = carbonsMatch.slice(1,5);
  newArray = newArray.map((itm, i) => {
    itm.y = positions[i];
    itm.initialY = positions[i];
    return itm
  })
  newArray.unshift(carbonsMatch.slice(0,2))
  return newArray
}

const carbonsFix = [
  { 
      type: "carbon",
      x: 350,
      y: 60,
      c: 6,
      name: 'glucose',
    },
    { 
      type: "carbon",
      x: 350,
      y: 160,
      c: 3,
      name: 'pyruvate',
    },
    { 
      type: "carbon",
      x: 350,
      y: 260,
      c: 2,
      name: 'acetyl',
    },
    { 
      type: "carbon",
      x: 350,
      y: 360,
      c: 1,
      name: 'carbonDioxide',
    },
  ];

const carbonNames = [
  { 
    type: 'box',
    x: 300,
    y: 30,
    width: 200,
    height: 60,
    text: 'Glucose:',
    name: 'glucose',
  },
  { 
    type:'box',
    x: 300,
    y: 130,
    width: 200,
    height: 60,
    text: 'Pyruvate:',
    name: 'pyruvate',
  },
  { 
    type:'box',
    x: 300,
    y: 230,
    width: 200,
    height: 60,
    text: 'Acetyl:',
    name: 'acetyl',
  },
  { 
    type: 'box',
    x: 300,
    y: 330,
    width: 200,
    height: 60,
    text: `CO${String.fromCharCode(8322)}:`,
    name: 'carbonDioxide',
  },
]

const carbons = [
  { 
    type: "carbon",
    x: 630,
    y: 190,
    c: 6,
    text: 'Glucose:',
    name: 'glucose',
    initialX: 630,
    initialY: 190,
    width: 132,
    height: 24,
    isDragging: false,
  },
  { 
    type: "carbon",
    x: 630,
    y: 250,
    c: 3,
    text: 'Pyruvate:',
    name: 'pyruvate',
    initialX: 630,
    initialY: 250,
    width: 60,
    height: 24,
    isDragging: false,
  },
  { 
    type: "carbon",
    x: 630,
    y: 310,
    c: 2,
    text: 'Acetyl:',
    name: 'acetyl',
    initialX: 630,
    initialY: 310,
    width: 36,
    height: 24,
    isDragging: false,
  },
  { 
    type: "carbon",
    x: 630,
    y: 370,
    c: 1,
    text: `CO${String.fromCharCode(8322)}:`,
    name: 'carbonDioxide',
    initialX: 630,
    initialY: 370,
    width: 12,
    height: 24,
    isDragging: false,
  },
];

const processesY= [180, 220, 260, 300, 340]

const processes = [
  { 
    type: 'process',
    x: 625,
    y: 180,
    text: 'Glycolysis', 
    initialX: 625,
    initialY: 180,
    width: 110,
    height: 26,
    isDragging: false,
    name: 'glycolysis'
  },
  { 
    type: 'process',
    x: 625,
    y: 220,
    text: 'Pyruvate oxidation',
    initialX: 625,
    initialY: 220,
    width: 160, // -10 initialX - 20 initialY
    height: 26,
    isDragging: false,
    name: 'pyruvate oxidation',
  },
  { 
    type: 'process',
    x: 625,
    y: 260,
    text: 'Krebs cycle',
    initialX: 625,
    initialY: 260,
    width: 110,
    height: 26,
    isDragging: false,
    name: 'krebs',
  },
  { 
    type: 'process',
    x: 625,
    y: 300,
    text: 'Electron Transport Chain',
    initialX: 625,
    initialY: 300,
    width: 205,
    height: 26,
    isDragging: false,
    name: 'electron transport chain'
  },
  { 
    type: 'process',
    x: 625,
    y: 340,
    text: 'Chemiosmosis',
    initialX: 625,
    initialY: 340,
    width: 130,
    height: 26,
    isDragging: false,
    name: 'chemiosmosis'
  },
];

const shuffleProcess = () => {
  const positions = shuffle(processesY);
  const newProcesses = processes.map((itm, i) => {
    itm.y = positions[i];
    itm.initialY = positions[i];
    return itm
  })
  return newProcesses
}

const reactants = [
  { 
    type: 'reactants',
    x: 620,
    y: 160,
    text: '2 NAD+',
    width: 66, 
    fill: 'white',  
    color: 'blue',
    initialX: 620,
    initialY: 160,
    height: 26,
    isDragging: false,
    name: '2 nad'
  },
  { 
    type: 'reactants',
    x: 720,
    y: 160,
    text: '2 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    initialX: 720,
    initialY: 160,
    height: 26,
    isDragging: false,
    name: '2 nadh'
  },
  { 
    type: 'reactants',
    x: 620,
    y: 190,
    text: '6 NAD+',
    width: 66, 
    fill: 'white',  
    color: 'blue',
    initialX: 620,
    initialY: 190,
    height: 26,
    isDragging: false,
    name: '6 nad'
  },
  { 
    type: 'reactants',
    x: 720,
    y: 190,
    text: '6 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    initialX: 720,
    initialY: 190,
    height: 26,
    isDragging: false,
    name: '6 nadh'
  },
  { 
    type: 'reactants',
    x: 620,
    y: 220,
    text: "2 FADH", 
    width: 66, 
    fill: 'white',
    color: 'blue',
    initialX: 620,
    initialY: 220,
    height: 26,
    isDragging: false,
    name: '2 fadh'
  },
  { 
    type: 'reactants',
    x: 720,
    y: 220,
    text: `2 FADH${String.fromCharCode(8322)}`,
    width: 70, 
    fill: 'blue', 
    color: 'white',
    initialX: 720,
    initialY: 220,
    height: 26,
    isDragging: false,
    name: '2 fadh2'
  },
  { 
    type: 'reactants',
    x: 720,
    y: 250,
    text: `2 ADP + P${String.fromCharCode(7522)}`,
    width: 90, 
    fill: 'white',  
    color:'black',
    initialX: 720,
    initialY: 250,
    height: 26,
    isDragging: false,
    name: '2 ADP'
  },
  { 
    type: 'reactants',
    x: 620,
    y: 250,
    text: `2 ATP`, 
    width: 53,  
    fill: 'yellow', 
    color:'black',
    initialX: 620,
    initialY: 250,
    height: 26,
    isDragging: false,
    name: '2 ATP'
  },
  { 
    type: 'reactants',
    x: 620,
    y: 280,
    text: `4 ATP`, 
    width: 53,  
    fill: 'yellow', 
    color:'black',
    initialX: 620,
    initialY: 280,
    height: 26,
    isDragging: false,
    name: '4 ATP'
  },
  { 
    type: 'reactants',
    x: 720,
    y: 280,
    text: `4 ADP + P${String.fromCharCode(7522)}`,
    width: 90, 
    fill: 'white',  
    color:'black',
    initialX: 720,
    initialY: 280,
    height: 26,
    isDragging: false,
    name: '4 ADP'
  },
  { 
    type: 'reactants',
    x: 620,
    y: 310,
    text: "2 Co-A", 
    width: 56,  
    fill: 'white', 
    color:'black',
    initialX: 620,
    initialY: 310,
    height: 26,
    isDragging: false,
    name: '2 CoA'
  },
];

const electrons = [
  {
    type: 'electrons',
    x: 180,
    y: 30,
    text: 'e',
    name: 'electrons',
    process: 'glycolysis',
    width: 25,  
    initialX: 180,
    initialY: 30,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 210,
    y: 30,
    text: 'e',
    name: 'electrons',
    process: 'glycolysis',
    width: 25,  
    initialX: 210,
    initialY: 30,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 100,
    y: 162,
    text: 'e',
    name: 'electrons',
    process: 'pyruvate oxidation',
    width: 25,  
    initialX: 100,
    initialY: 162,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 220,
    y: 162,
    text: 'e',
    name: 'electrons',
    process: 'pyruvate oxidation',
    width: 25,  
    initialX: 220,
    initialY: 162,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 102,
    y: 240,
    text: 'e',
    name: 'electrons',
    process: 'krebs',
    width: 25,  
    initialX: 102,
    initialY: 240,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 132,
    y: 240,
    text: 'e',
    name: 'electrons',
    process: 'krebs',
    width: 25,  
    initialX: 132,
    initialY: 240,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 122,
    y: 221,
    text: 'e',
    name: 'electrons',
    process: 'krebs',
    width: 25,  
    initialX: 122,
    initialY: 221,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 222,
    y: 240,
    text: 'e',
    name: 'electrons',
    process: 'krebs',
    width: 25,  
    initialX: 222,
    initialY: 240,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 252,
    y: 240,
    text: 'e',
    name: 'electrons',
    process: 'krebs',
    width: 25,  
    initialX: 252,
    initialY: 240,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 242,
    y: 221,
    text: 'e',
    name: 'electrons',
    process: 'krebs',
    width: 25,  
    initialX: 242,
    initialY: 221,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 100,
    y: 221,
    text: 'e',
    name: 'electrons',
    process: 'krebs',
    width: 25,  
    initialX: 100,
    initialY: 221,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 220,
    y: 221,
    text: 'e',
    name: 'electrons',
    process: 'krebs',
    width: 25,  
    initialX: 220,
    initialY: 221,
    height: 15,
    isDragging: false,
  },
  {
    type: 'electrons',
    x: 410,
    y: 359,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 410,
    initialY: 359,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  {
    type: 'electrons',
    x: 380,
    y: 359,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 380,
    initialY: 359,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  {
    type: 'electrons',
    x: 440,
    y: 359,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 440,
    initialY: 359,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  {
    type: 'electrons',
    x: 440,
    y: 330,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 440,
    initialY: 330,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  {
    type: 'electrons',
    x: 366,
    y: 334,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 366,
    initialY: 334,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  {
    type: 'electrons',
    x: 456,
    y: 334,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 456,
    initialY: 334,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  {
    type: 'electrons',
    x: 456,
    y: 346,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 456,
    initialY: 346,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  {
    type: 'electrons',
    x: 366,
    y: 346,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 366,
    initialY: 346,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  {
    type: 'electrons',
    x: 380,
    y: 330,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 380,
    initialY: 330,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  {
    type: 'electrons',
    x: 410,
    y: 330,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 410,
    initialY: 330,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  { // 22 for NADH electrons
    type: 'oxygen',
    x: 700,
    y: 280,
    name: 'etc',
    process: 'electron transport chain',
    width: 20,  
    quantity: 2,
    initialX: 700,
    initialY: 280,
    height: 20,
    isDragging: false,
  },
  { // 23
    type: 'electrons',
    x: 380,
    y: 330,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 380,
    initialY: 330,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  { // 24
    type: 'electrons',
    x: 410,
    y: 330,
    text: 'e',
    name: 'etc',
    process: 'electron transport chain',
    width: 25,  
    initialX: 410,
    initialY: 330,
    height: 15,
    isDragging: false,
    ETCposition: false,
  },
  { // 25 for FADH electrons
    type: 'oxygen',
    x: 700,
    y: 280,
    name: 'etc',
    process: 'electron transport chain',
    width: 20,  
    quantity: 2,
    initialX: 700,
    initialY: 280,
    height: 20,
    isDragging: false,
  },
];

const NADH = [
  { 
    type: 'nadhE',
    x: 292,
    y: 48,
    text: '1 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: 'electrons' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 292,
    y: 196,
    text: '1 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: 'electrons' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 304,
    y: 272,
    text: '1 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: 'electrons' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 304,
    y: 272,
    text: '2 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: 'electrons' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 304,
    y: 272,
    text: '3 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: 'electrons' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 304,
    y: 272,
    text: '4 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: 'electrons' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 304,
    y: 272,
    text: '5 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: 'electrons' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 304,
    y: 272,
    text: '6 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: 'electrons' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 300,
    y: 336,
    text: `1 FADH${String.fromCharCode(8322)}`,
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: 'electrons' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 380,
    y: 330,
    text: '6 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: '6 nadh' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 380,
    y: 330,
    text: '8 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: '8 nadh' // so that it matches the electrons above
  },
  { 
    type: 'nadhE',
    x: 380,
    y: 330,
    text: '10 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: '10 nadh' // so that it matches the electrons above
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '10 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '9 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '8 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '7 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '6 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '5 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '4 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '3 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '2 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: '1 NADH',
    width: 76, 
    fill: 'blue',  
    color: 'white',
    name: 'etc' // so that it matches the electrons above in ETC
  },
  { 
    type: 'nadhE',
    x: 380,
    y: 330,
    text: `2 FADH${String.fromCharCode(8322)}`,
    width: 70, 
    fill: 'blue', 
    color: 'white',
    name: '2 fadh2',
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: `2 FADH${String.fromCharCode(8322)}`,
    width: 70, 
    fill: 'blue', 
    color: 'white',
    name: 'etc',
  },
  { 
    type: 'reactants',
    x: 380,
    y: 330,
    text: `1 FADH${String.fromCharCode(8322)}`,
    width: 70, 
    fill: 'blue', 
    color: 'white',
    name: 'etc',
  },
]

const glycolysis = [
  { 
    type: 'process',
    x: 360,
    y: 45,
    text: 'Glycolysis', 
    name: 'glycolysis'
  },
  { 
    type: "carbon",
    x: 120,
    y: 24,
    c: 6,
    text: 'Glucose:',
    name: 'glucose'
  },
  { 
    type: 'reactants',
    x: 284,
    y: 12,
    text: '2 NAD+',
    width: 66, 
    fill: 'white',  
    color: 'blue',
    name: '2 nad'
  },
  { 
    type: 'reactants',
    x: 9.6,
    y: 6,
    text: `4 ADP + P${String.fromCharCode(7522)}`,
    width: 90, 
    fill: 'white',  
    color:'black',
    name: '4 ADP'
  },
  { 
    type: 'reactants',
    x: 28,
    y: 28,
    text: `2 ATP`, 
    width: 53,  
    fill: 'yellow', 
    color:'black',
    name: '2 ATP'
  },
  {
    type: 'arrow',
    fromx: 183.2,
    fromy: 32,
    tox: 120,
    toy: 92,
  },
  {
    type: 'arrow',
    fromx: 176.8,
    fromy: 32,
    tox: 240,
    toy: 92,
  },  
  {
    type: 'curve',
    x:272,
    y: 48, 
    r1: 12, 
    r2: 20, 
    side: 'right',
  },   
  {
    type: 'curve',
    x: 84,
    y: 48, 
    r1: 12, 
    r2: 22, 
    side: 'left',
  },
  { 
    type: 'nadhE',
    x: 292,
    y: 48,
    text: '2 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: '2 nadh',
    initialX: 292,
    initialY: 48,
    height: 26,
    isDragging: false,
  },
  { 
    type: "carbon",
    x: 96,
    y: 108,
    c: 3,
    name: 'pyruvate',
    initialX: 96,
    initialY: 108,
    width: 60,
    height: 24,
    isDragging: false,
  },
  { 
    type: "carbon",
    x: 216,
    y: 108,
    c: 3,
    name: 'pyruvate',
    initialX: 216,
    initialY: 108,
    width: 60,
    height: 24,
    isDragging: false,
  },
  { 
    type: 'reactants',
    x: 9.6,
    y: 76,
    text: `2 ADP + P${String.fromCharCode(7522)}`,
    width: 90, 
    fill: 'white',  
    color:'black',
    name: '2 ADP'
  },
  { 
    type: 'reactants',
    x: 28,
    y: 56,
    text: `4 ATP`, 
    width: 53,  
    fill: 'yellow', 
    color:'black',
    name: '4 ATP'
  },
]

const netATP = [
  { 
    type: 'reactants',
    x: 18,
    y: 42,
    text: `Net: 2 ATP`, 
    width: 90,  
    fill: 'yellow', 
    color:'black',
    name: '2 ATP'
  },
]
const pyruvateOx = [
  { 
    type: 'process',
    x: 310,
    y: 165,
    text: 'Pyruvate oxidation', 
    name: 'pyruvate oxidation'
  },
  {
    type: 'arrow',
    fromx: 120,
    fromy: 120,
    tox: 120,
    toy: 136,
  }, 
  {
    type: 'arrow',
    fromx: 240,
    fromy: 120,
    tox: 240,
    toy: 136,
  },
  { 
    type: "carbon",
    x: 96,
    y: 156,
    c: 3,
    name: 'pyruvate'
  },
  { 
    type: "carbon",
    x: 216,
    y: 156,
    c: 3,
    name: 'pyruvate'
  },
  { 
    type: 'reactants',
    x: 44,
    y: 166,
    text: "2 Co-A", 
    width: 60,  
    fill: 'white', 
    color:'black',
    name: '2 CoA'
  },
  { 
    type: 'reactants',
    x: 284,
    y: 164,
    text: '2 NAD+',
    width: 66, 
    fill: 'white',  
    color: 'blue',
    name: '2 nad'
  },
  {
    type: 'curve',
    x:276,
    y: 192, 
    r1: 12, 
    r2: 20, 
    side: 'right',
  }, 
  { 
    type: 'nadhE',
    x: 292,
    y: 196,
    text: '2 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: '2 nadh',
    initialX: 292,
    initialY: 196,
    height: 26,
    isDragging: false,
  },
  {
    type: 'arrow',
    fromx: 120,
    fromy: 168,
    tox: 120,
    toy: 216,
  }, 
  {
    type: 'arrow',
    fromx: 240,
    fromy: 168,
    tox: 240,
    toy: 216,
  },
  {
    type: 'arrow',
    fromx: 80,
    fromy: 192,
    tox: 96,
    toy: 212,
    name: 'for Co-A'
  }, 
  {
    type: 'text',
    x: 56,
    y: 236,
    text: 'Co-A-',
    color: 'black',
    name: 'CoA'
  },
  {
    type: 'text',
    x: 176,
    y: 236,
    text: 'Co-A-',
    color: 'black',
    name: 'CoA'
  },
  { 
    type: "carbon",
    x: 108,
    y: 232,
    c: 2,
    text: 'Acetyl:',
    name: 'acetyl'
  },
  { 
    type: "carbon",
    x: 228,
    y: 232,
    c: 2,
    text: 'Acetyl:',
    name: 'acetyl'
  },
  {
    type: 'arrow',
    fromx: 120,
    fromy: 168,
    tox: 144,
    toy: 192,
  }, 
  {
    type: 'arrow',
    fromx: 240,
    fromy: 168,
    tox: 216,
    toy: 192,
  }, 
  { 
    type: "carbon",
    x: 160,
    y: 200,
    c: 1,
    name: 'carbonDioxide'
  },
  { 
    type: "carbon",
    x: 200,
    y: 200,
    c: 1,
    name: 'carbonDioxide'
  },
]

const krebs = [
  {
    type: 'krebs',
    text: 'Krebs',
    x:132,
    y:304
  }, 
  {
    type: 'krebs',
    text: 'Krebs',
    x:232,
    y:304
  },  
  { 
    type: 'reactants',
    x: 296,
    y: 244,
    text: '6 NAD+',
    width: 66, 
    fill: 'white',  
    color: 'blue',
    name: '6 nad'
  },
  { 
    type: 'reactants',
    x: 8,
    y: 248,
    text: `2 ADP + P${String.fromCharCode(7522)}`,
    width: 90, 
    fill: 'white',  
    color:'black',
    name: '2 ADP'
  },
  { 
    type: 'reactants',
    x: 292,
    y: 308,
    text: "2 FADH", 
    width: 66, 
    fill: 'white',
    color: 'blue',
    name: '2 fadh'
  },
  {
    type: 'curve',
    x: 288,
    y: 272, 
    r1: 12, 
    r2: 16, 
    side: 'right',
  }, 
  {
    type: 'arrow',
    fromx:120,
    fromy: 240,
    tox: 120,
    toy: 260,
  },
  {
    type: 'arrow',
    fromx: 240,
    fromy: 240,
    tox: 240,
    toy: 260,
  },
  {
    type: 'curve',
    x: 76,
    y: 280, 
    r1: 12, 
    r2: 16, 
    side: 'left',
  },
  {
    type: 'curve',
    x: 288,
    y: 332, 
    r1: 12, 
    r2: 16, 
    side: 'right',
  },
  { 
    type: 'nadhE',
    x: 304,
    y: 272,
    text: '6 NADH',
    width: 68, 
    fill: 'blue',  
    color: 'white',
    name: '6 nadh',
    initialX: 304,
    initialY: 272,
    height: 26,
    isDragging: false,
  },
  { 
    type: 'nadhE',
    x: 300,
    y: 336,
    text: `2 FADH${String.fromCharCode(8322)}`,
    width: 70, 
    fill: 'blue', 
    color: 'white',
    name: '2 fadh2',
    initialX: 300,
    initialY: 336,
    height: 26,
    isDragging: false,
  },
  { 
    type: 'reactants',
    x: 20,
    y: 288,
    text: `2 ATP`, 
    width: 53,  
    fill: 'yellow', 
    color:'black',
    name: '2 ATP'
  },
  {
    type: 'arrow',
    fromx: 104,
    fromy: 320,
    tox: 88,
    toy: 326,
  },
  {
    type: 'arrow',
    fromx: 208,
    fromy: 322,
    tox: 192,
    toy: 326,
  },
  { 
    type: "carbon",
    x: 68,
    y: 324,
    c: 1,
    name: 'carbonDioxide'
  },
  { 
    type: "carbon",
    x: 76,
    y: 340,
    c: 1,
    name: 'carbonDioxide'
  },
  { 
    type: "carbon",
    x: 172,
    y: 324,
    c: 1,
    name: 'carbonDioxide'
  },
  { 
    type: "carbon",
    x: 184,
    y: 338,
    c: 1,
    name: 'carbonDioxide'
  }
];

const ETC = [
  { 
    type: 'process',
    x: 480,
    y: 355,
    text: 'ETC', 
    name: 'electron transport chain'
  },
];

const chemiosmosis = [
  { 
    type: 'process',
    x: 460,
    y: 170,
    text: 'ATP synthase', 
    name: 'chemiosmosis'
  },
]

const moleculesFixed = [
  { // water molecule
    type: 'oxygen',
    x: 330,
    y: 75,
    name: 'oxygen',
    quantity: 1 ,
  },
  {// water molecule
    type: 'proton',
    x: 318,
    y: 70,
    name: 'proton',
    radius: 3,
    color:'red',
    molType: 'proton',
  },
  {// water molecule
    type: 'proton',
    x: 318,
    y: 80,
    name: 'proton',
    radius: 3,
    color:'red',
    molType: 'proton',
  },
  {
    type: 'electrons',
    x: 320,
    y: 375,
    name: 'electrons',
  },
  { // 
    type: 'oxygen',
    x: 320,
    y: 270,
    name: 'oxygen',
    quantity: 2 ,
  },
  {
    type: 'proton',
    x: 320,
    y: 175,
    name: 'proton',
    radius: 3,
    color:'red',
    molType: 'proton',
  },
]

const moleculeBoxes = [
  { 
    type: 'box',
    x: 300,
    y: 50,
    text: '',
    name: 'water',
    width: 250,
    height: 50,
  },
  { 
    type:'box',
    x: 300,
    y: 150,
    text: '',
    name: 'proton',
    width: 250,
    height: 50,
  },
  { 
    type:'box',
    x: 300,
    y: 250,
    text: '',
    name: 'oxygen',
    width: 250,
    height: 50,
  },
  { 
    type: 'box',
    x: 300,
    y: 350,
    text: ``,
    name: 'electron',
    width: 250,
    height: 50,
  },
]

const moleculeNames = [
  { 
    type: "process",
    x: 580,
    y: 50,
    text: 'Drag labels from here:',
  },
  { 
    type: 'process',
    x: 625,
    y: 150,
    text: `Water (H${String.fromCharCode(8322)}0)`, 
    initialX: 625,
    initialY: 150,
    width: 110,
    height: 26,
    isDragging: false,
    name: 'water'
  },
  { 
    type: 'process',
    x: 625,
    y: 200,
    text: 'Electrons', 
    initialX: 625,
    initialY: 200,
    width: 110,
    height: 26,
    isDragging: false,
    name: 'electrons'
  },
  { 
    type: 'process',
    x: 625,
    y: 250,
    text: `Oxygen O${String.fromCharCode(8322)}`, 
    initialX: 625,
    initialY: 250,
    width: 110,
    height: 26,
    isDragging: false,
    name: 'oxygen'
  },
  { 
    type: 'process',
    x: 625,
    y: 300,
    text: 'Protons (H+)', 
    initialX: 625,
    initialY: 300,
    width: 110,
    height: 26,
    isDragging: false,
    name: 'proton'
  },
]

const moleculeLabels = [
  { 
    type: 'process',
    x: 400,
    y: 78,
    text: 'Water', 
    name: 'water'
  },
  { 
    type: 'process',
    x: 400,
    y: 378,
    text: 'Electrons', 
    name: 'electrons'
  },
  { 
    type: 'process',
    x: 400,
    y: 278,
    text: `Oxygen O${String.fromCharCode(8322)}`, 
    name: 'oxygen'
  },
  { 
    type: 'process',
    x: 400,
    y: 178,
    text: 'Protons', 
    name: 'proton'
  },
];

const States = [
  {
    drawCell: false,
    oxygen: false,
    protons: false,
    ATPCount:0,
    dragok: false,
    startX: null,
    startY: null,
    options: shuffleCarbons(),
    fixtures: carbonNames,
    allDrawings: carbonsFix,
    question: 'Drag the carbon diagrams to the corresponding molecule name',
    correctAnswer: ['glucose', 'pyruvate', 'acetyl', 'carbonDioxide'],
    incorrectMessage: 'A Glucose molecule has 6 carbons, pyruvate has 3 carbons, acetyl has 2 carbons and carbon dioxide has one carbon atom',
    targets: [{ minX: 320, maxX: 550, minY: 320, maxY:400, name: 'carbonDioxide'},
              { minX: 320, maxX: 550, minY: 220, maxY:300, name: 'acetyl'},
              { minX: 320, maxX: 550, minY: 120, maxY:200, name: 'pyruvate'},
              { minX: 320, maxX: 550, minY: 20, maxY:100, name: 'glucose'},],
    inCellDrag: false,
  },
  {
    drawCell: true,
    oxygen: false,
    protons: false,
    options: shuffleProcess(), // processes,
    ATPCount:0,
    fixtures:[],
    currentStep: 1,
    currentProcess: 'glycolysis',
    question: 'Drag the first step in cellular respiration to the area of the cell where it occurs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['glycolysis'],
    incorrectMessage: 'Drag the glycolysis into the cytoplasm',
    targets: [{ minX: 0, maxX: 560, minY: 0, maxY:132, name: 'glycolysis'}],
    inCellDrag: false,
  },
  {
    options:carbons,
    ATPCount:0,
    fixtures: glycolysis.slice(0,1),
    currentProcess: 'glycolysis',
    question: 'Drag the carbon molecule used as a reactant into the area where glycolysis occurs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['glucose'],
    incorrectMessage: 'Drag the glucose molecule into the cytoplasm',
    targets: [{ minX: 0, maxX: 560, minY: 0, maxY:132, name: 'glucose'}],
    inCellDrag: false,
  },
  {
    options:reactants,
    ATPCount:0,
    fixtures: glycolysis.slice(0,2),
    currentProcess: 'glycolysis',
    allDrawings: glycolysis.slice(2,5),
    question: 'Drag other glycolysis reactants into the area where glycolysis occurs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['2 nad', '2 ATP', '4 ADP'],
    incorrectMessage: `Drag: 2 ATP, 2 NAD+ and 4 ADP + P${String.fromCharCode(7522)} into the cytoplasm`,
    targets: [{ minX: 0, maxX: 560, minY: 0, maxY:132, name: '2 nad'},
    { minX: 0, maxX: 560, minY: 0, maxY:132, name: '2 ATP'},
    { minX: 0, maxX: 560, minY: 0, maxY:132, name: '4 ADP'},],
    inCellDrag: false,
  },
  {
    options:electrons.slice(0,2),
    ATPCount:'-2',
    fixtures:glycolysis.slice(0,8),
    currentProcess: 'glycolysis',
    allDrawings: NADH.slice(0,1),
    question: 'Drag a pair of electrons from glucose to NAD+',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['electrons','electrons'],
    incorrectMessage: 'Drag a pair of electrons from glucose to NAD+',
    targets: [{ minX: 265, maxX: 365, minY: 5, maxY:40, name: 'electrons'},
    { minX: 265, maxX: 365, minY: 5, maxY:40, name: 'electrons'},],
    inCellDrag: true,
  },
  {
    options:carbons,
    ATPCount:'-2',
    fixtures:glycolysis.slice(0,10),
    currentProcess: 'glycolysis',
    allDrawings: glycolysis.slice(10,11),
    question: 'Drag the carbon molecule that is the product of glycolysis',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['pyruvate'],
    incorrectMessage: `Drag pyruvate into the cytoplasm`,
    targets: [{ minX: 0, maxX: 560, minY: 0, maxY:132, name: 'pyruvate'}],
    inCellDrag: false,
  },
  {
    options:reactants,
    ATPCount:'-2',
    fixtures:glycolysis.slice(0,12),
    currentProcess: 'glycolysis',
    allDrawings: glycolysis.slice(12,14),
    question: 'Drag the total ATP produced in glycolysis',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['4 ATP'],
    incorrectMessage: `A total of 4 ATP are produced during glycolysis`,
    targets: [{ minX: 0, maxX: 560, minY: 0, maxY:132, name: '4 ATP'}],
    inCellDrag: false,
  },
  {
    options:reactants,
    ATPCount:'-2',
    fixtures:glycolysis,
    currentProcess: 'glycolysis',
    allDrawings: netATP,
    question: 'Drag the net ATP produced in glycolysis',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['2 ATP'],
    incorrectMessage: `4 ATP were produced during glycolysis but 2 were needed to start the process, for a net gain of 2 ATP`,
    targets: [{ minX: 0, maxX: 560, minY: 0, maxY:132, name: '2 ATP'}],
    inCellDrag: false,
  },
  {
    options:processes,
    ATPCount:2,
    fixtures:glycolysis.concat(netATP),
    currentProcess: 'pyruvate oxidation',
    question: 'Drag the next step in cellular respiration to the area of the cell where it occurs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['pyruvate oxidation'],
    incorrectMessage: 'Drag the pyruvate oxidation into the mitochondrial matrix',
    targets: [{ minX: 42, maxX: 476, minY: 144, maxY:368, name: 'pyruvate oxidation'}],
    inCellDrag: false,
  },
  {
    options: glycolysis.slice(10,12),
    ATPCount:2,
    fixtures:glycolysis.slice(0,10).concat(glycolysis.slice(12)).concat(pyruvateOx.slice(0,1)).concat(netATP),
    allDrawings: pyruvateOx.slice(3,5),
    currentProcess: 'pyruvate oxidation',
    question: 'Drag both pyruvate molecules into the mitochondria to start Pyruvate Oxidation',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['pyruvate', 'pyruvate'],
    incorrectMessage: 'Drag both pyruvate molecules into the mitochondrial matrix',
    targets: [{ minX: 42, maxX: 476, minY: 144, maxY:368, name: 'pyruvate'},
    { minX: 42, maxX: 476, minY: 144, maxY:368, name: 'pyruvate'},],
    inCellDrag: true,
  },
  {
    options: reactants,
    ATPCount: 2,
    fixtures:glycolysis.concat(pyruvateOx.slice(0,5)).concat(netATP),
    allDrawings: pyruvateOx.slice(5,7),
    currentProcess: 'pyruvate oxidation',
    question: 'Drag other reactants into the area where Pyruvate Oxidation occurs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['2 nad', '2 CoA'],
    incorrectMessage: 'Drag NAD+ and 2 CoA into the mitochondrial matrix',
    targets: [{ minX: 42, maxX: 476, minY: 144, maxY:368, name: '2 nad'},
    { minX: 42, maxX: 476, minY: 144, maxY:368, name: '2 CoA'},],
    inCellDrag: false,
  },
  {
    options: electrons.slice(2,4),
    ATPCount: 2,
    fixtures:glycolysis.concat(pyruvateOx.slice(0,8)).concat(netATP),
    allDrawings: NADH.slice(1,2),
    currentProcess: 'pyruvate oxidation',
    question: 'Drag both pairs of electrons from pyruvate to NAD+',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['electrons','electrons'],
    incorrectMessage: 'Drag both pairs of electrons from pyruvate to NAD+',
    targets: [{ minX: 260, maxX: 360, minY: 150, maxY: 200, name: 'electrons'},
    { minX: 260, maxX: 360, minY: 150, maxY: 200, name: 'electrons'}, ],
    inCellDrag: true,
  },
   {
    options: carbons,
    ATPCount: 2,
    fixtures:glycolysis.concat(pyruvateOx.slice(0,12)).concat(netATP),
    allDrawings: pyruvateOx.slice(11,17),
    currentProcess: 'pyruvate oxidation',
    question: 'Drag the carbon molecule product of Pyruvate Oxidation to the area where Pyruvate Oxidation occurs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['acetyl'],
    incorrectMessage: 'Drag acetyl into the mitochondrial matrix',
    targets: [{ minX: 42, maxX: 476, minY: 144, maxY:368, name: 'acetyl'}],
    inCellDrag: false,
  },
  {
    options: carbons,
    ATPCount: 2,
    fixtures:glycolysis.concat(pyruvateOx.slice(0,18)).concat(netATP),
    allDrawings: pyruvateOx.slice(17,20),
    currentProcess: 'pyruvate oxidation',
    question: 'Drag the carbon molecule where the remaining carbons went',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['carbonDioxide'],
    incorrectMessage: 'Drag carbon dioxide into the mitochondrial matrix',
    targets: [{ minX: 42, maxX: 476, minY: 144, maxY:368, name: 'carbonDioxide'}],
    inCellDrag: false,
  },
   {
    options:processes,
    ATPCount:2,
    fixtures:glycolysis.concat(pyruvateOx).concat(netATP),
    currentProcess: 'krebs',
    question: 'Drag the next step in cellular respiration to the area of the cell where it occurs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['krebs'],
    incorrectMessage: 'Drag the krebs into the mitochondrial matrix',
    targets: [{ minX: 42, maxX: 476, minY: 144, maxY:368, name: 'krebs'}],
    inCellDrag: false,
  },
  {
    options: reactants,
    ATPCount: 2,
    fixtures:glycolysis.concat(pyruvateOx).concat(krebs.slice(0,2)).concat(netATP),
    allDrawings: krebs.slice(2,5),
    currentProcess: 'krebs',
    question: "Drag other reactants into the area where Krebs cycle occurs",
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['6 nad', '2 ADP', '2 fadh'],
    incorrectMessage: `Drag 6 NAD+, 2 FADH and 2 ADP + P${String.fromCharCode(7522)} into the mitochondrial matrix`,
    targets: [{ minX: 42, maxX: 476, minY: 144, maxY:368, name: '6 nad'},
    { minX: 42, maxX: 476, minY: 144, maxY:368, name: '2 ADP'},
    { minX: 42, maxX: 476, minY: 144, maxY:368, name: '2 fadh'}],
    inCellDrag: false,
  },
    {
    options: electrons.slice(4,10),
    ATPCount: 2,
    fixtures:glycolysis.concat(pyruvateOx).concat(krebs.slice(0,10)).concat(netATP),
    allDrawings: NADH.slice(2,8),
    currentProcess: 'krebs',
    question: 'Drag six pairs of electrons, three from each acetyl-CoA to NAD+',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['electrons','electrons','electrons','electrons','electrons','electrons'],
    incorrectMessage: 'Drag six pairs of electrons, three from each acetyl-CoA to NAD+',
    targets: [{ minX: 290, maxX: 370, minY: 234, maxY: 280, name: 'electrons'},
    { minX: 290, maxX: 370, minY: 234, maxY: 280, name: 'electrons'},
    { minX: 290, maxX: 370, minY: 234, maxY: 280, name: 'electrons'},],
    inCellDrag: true,
  },
  {
    drawCell: true,
    options: electrons.slice(10,12),
    ATPCount: 2,
    fixtures:glycolysis.concat(pyruvateOx).concat(krebs.slice(0,11)).concat(netATP),
    allDrawings: NADH.slice(8,9),
    currentProcess: 'krebs',
    question: 'Drag a pair of electrons from each acetyl-CoA to FADH',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['electrons','electrons'],
    incorrectMessage: 'Drag a pair of electrons from each acetyl-CoA to FADH',
    targets: [{ minX: 285, maxX: 370, minY: 300, maxY: 340, name: 'electrons'},
    { minX: 285, maxX: 370, minY: 300, maxY: 340, name: 'electrons'},],
    inCellDrag: true,
  },
    {
    options: reactants,
    ATPCount: 2,
    fixtures:glycolysis.concat(pyruvateOx).concat(krebs.slice(0,12)).concat(netATP),
    allDrawings: krebs.slice(12,13),
    currentProcess: 'krebs',
    question: "Drag the number of ATPs produced during Krebs cycle",
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['2 ATP'],
    incorrectMessage: `Drag 2 ATP into the mitochondrial matrix`,
    targets: [{ minX: 42, maxX: 476, minY: 144, maxY: 368, name: '2 ATP'}], 
    inCellDrag: false,
  },
   {
    options: carbons,
    ATPCount: 4,
    fixtures:glycolysis.concat(pyruvateOx).concat(krebs.slice(0,15)).concat(netATP),
    allDrawings: krebs.slice(15,17),
    currentProcess: 'krebs',
    question: 'Drag the carbon molecules where the remaining carbons went',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['carbonDioxide'],
    incorrectMessage: 'Drag carbon dioxide into the mitochondrial matrix',
    targets: [{ minX: 42, maxX: 476, minY: 144, maxY: 368, name: 'carbonDioxide'}], 
    inCellDrag: false,
  },
   {
    options:processes,
    ATPCount:4,
    fixtures:glycolysis.concat(pyruvateOx).concat(krebs).concat(netATP),
    currentProcess: 'intermediate',
    question: 'Drag the next step in cellular respiration to the area of the cell where it occurs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['electron transport chain'],
    incorrectMessage: 'Drag the Electron Transport Chain to the inner mitochondrial membrane',
    targets: [{ minX: 300, maxX: 600, minY: 140, maxY: 400, name: 'electron transport chain'}], 
    inCellDrag: false,
  },
    {
    options: krebs.slice(10,11),
    ATPCount: 4,
    fixtures:glycolysis.concat(pyruvateOx).concat(krebs.slice(0,10)).concat(krebs.slice(11,19)).concat(ETC).concat(netATP),
    allDrawings: NADH.slice(9,10),
    currentProcess: 'intermediate',
    question: 'Drag 6 NADH molecules from Krebs cycle into the ETC',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['6 nadh'],
    incorrectMessage: 'Drag 6 NADH molecules from Krebs cycle into the ETC',
    targets: [{ minX: 285, maxX: 450, minY: 150, maxY: 400, name: '6 nadh'}], 
    inCellDrag: true,
  },
  {
    options: pyruvateOx.slice(8,9),
    ATPCount: 4,
    fixtures:glycolysis.concat(pyruvateOx.slice(0,8)).concat(pyruvateOx.slice(9,20)).concat(krebs.slice(0,10)).concat(krebs.slice(11,19)).concat(NADH.slice(9,10)).concat(ETC).concat(netATP),
    allDrawings: NADH.slice(10,11),
    currentProcess: 'intermediate',
    question: 'Drag 2 NADH molecules from Pyruvate oxidation into the ETC',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['2 nadh'],
    incorrectMessage: 'Drag 2 NADH molecules from Pyruvate oxidation into the ETC',
    targets: [{ minX: 285, maxX: 450, minY: 150, maxY: 400, name: '2 nadh'}], 
    inCellDrag: true,
  },
  {
    options: glycolysis.slice(9,10),
    ATPCount: 4,
    fixtures:glycolysis.slice(0,9).concat(glycolysis.slice(10,17)).concat(pyruvateOx.slice(0,8)).concat(pyruvateOx.slice(9,20)).concat(krebs.slice(0,10)).concat(krebs.slice(11,19)).concat(NADH.slice(10,11)).concat(ETC).concat(netATP),
    allDrawings: NADH.slice(11,12),
    currentProcess: 'intermediate',
    question: 'Drag 2 NADH molecules from Glycolysis into the ETC, this will cost you 2 ATPs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['2 nadh'],
    incorrectMessage: 'Drag 2 NADH molecules from Glycolysis into the ETC',
    targets: [{ minX: 285, maxX: 450, minY: 150, maxY: 400, name: '2 nadh'}], 
    inCellDrag: true,
  },
  {
    drawCell: true,
    options: electrons.slice(12,23), // includes oxygen
    ATPCount: 2,
    chemiosmosis: false,
    oxygen: true,
    protons: true,
    fixtures:glycolysis.slice(0,9).concat(glycolysis.slice(10,17)).concat(pyruvateOx.slice(0,8)).concat(netATP).concat(pyruvateOx.slice(9,20)).concat(krebs.slice(0,10)).concat(krebs.slice(11,19)).concat(ETC).concat(NADH.slice(12,13)),
    allDrawings: NADH.slice(13,22),
    currentProcess: 'electron transport chain',
    question: 'Drag each pair of electrons from NADH to each of the proteins in the ETC',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['etc'],
    incorrectMessage: 'Drag electrons from NADH one by one to each of the proteins in the ETC, only one pair of electrons can be in one protein at a time and they must go in order through each protein',
    targets: [{ minX: 300, maxX: 400, minY: 150, maxY: 400, name: 'etc'}], 
    inCellDrag: true,
  },
  {
    drawCell: false,
    oxygen: false,
    protons: false,
    ATPCount:0,
    dragok: false,
    startX: null,
    startY: null,
    options: moleculeNames,
    fixtures: moleculeBoxes.concat(moleculesFixed),
    allDrawings: moleculeLabels,
    currentProcess: 'intermediate',
    question: 'Drag the labels to the corresponding molecule diagram',
    correctAnswer: ['electrons', 'oxygen', 'proton', 'water'],
    incorrectMessage: 'Drag label to corresponding grey box with diagram',
    targets: [{ minX: 290, maxX: 560, minY: 340, maxY:410, name: 'electrons'},
              { minX: 290, maxX: 560, minY: 240, maxY:310, name: 'oxygen'},
              { minX: 290, maxX: 560, minY: 140, maxY:210, name: 'proton'},
              { minX: 290, maxX: 560, minY: 40, maxY:110, name: 'water'},],
    inCellDrag: false,
  },
    {
    drawCell: true,
    options: krebs.slice(11,12),
    ATPCount: 4,
    chemiosmosis: false,
    oxygen: false,
    protons: true,
    fixtures:glycolysis.slice(0,9).concat(glycolysis.slice(10,17)).concat(pyruvateOx.slice(0,8)).concat(pyruvateOx.slice(9,20)).concat(netATP).concat(krebs.slice(0,10)).concat(krebs.slice(12,19)).concat(ETC),
    allDrawings: NADH.slice(22,23),
    currentProcess: 'intermediate',
    question: `Drag 2 FADH${String.fromCharCode(8322)} molecules from Krebs cycle into the ETC`,
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['2 fadh2'],
    incorrectMessage: `Drag 2 FADH${String.fromCharCode(8322)} molecules from Krebs cycle into the ETC`,
    targets: [{ minX: 285, maxX: 450, minY: 150, maxY: 400, name: '2 fadh2'}], 
    inCellDrag: true,
  },
  {
    drawCell: true,
    options: electrons.slice(23,26), // includes oxygen
    ATPCount: 2,
    oxygen: true,
    protons: true,
    chemiosmosis: false,
    fixtures:glycolysis.slice(0,9).concat(glycolysis.slice(10,17)).concat(pyruvateOx.slice(0,8)).concat(netATP).concat(pyruvateOx.slice(9,20)).concat(krebs.slice(0,10)).concat(krebs.slice(12,19)).concat(ETC).concat(NADH.slice(23,24)),
    allDrawings: NADH.slice(24,25),
    currentProcess: 'electron transport chain',
    question: `Drag a pair of electrons from FADH${String.fromCharCode(8322)} to the second protein in the ETC`,
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['etc'],
    incorrectMessage: `electrons from FADH${String.fromCharCode(8322)} skip the first protein in the ETC`,
    targets: [{ minX: 300, maxX: 400, minY: 150, maxY: 400, name: 'etc'},
    { minX: 300, maxX: 400, minY: 150, maxY: 400, name: 'etc'},], 
    inCellDrag: true,
  },
  {
    options:processes,
    ATPCount:2,
    protons: true,
    chemiosmosis: false,
    fixtures:glycolysis.slice(0,9).concat(glycolysis.slice(10,17)).concat(pyruvateOx.slice(0,8)).concat(pyruvateOx.slice(9,20)).concat(krebs.slice(0,10)).concat(krebs.slice(12,19)).concat(ETC).concat(netATP),
    currentProcess: 'intermediate',
    question: 'Drag the next step in cellular respiration to the area of the cell where it occurs',
    dragok: false,
    startX: null,
    startY: null,
    correctAnswer: ['chemiosmosis'],
    incorrectMessage: 'Drag the Chemiosmosis to the intermembrane space in the mitochondrion',
    targets: [{ minX: 300, maxX: 600, minY: 140, maxY: 400, name: 'chemiosmosis'}], 
    inCellDrag: false,
  },
  {
    options: false,
    ATPCount:2,
    protons: true,
    chemiosmosis:true, // start drawing ATP synthase
    fixtures:glycolysis.slice(0,9).concat(glycolysis.slice(10,17)).concat(pyruvateOx.slice(0,8)).concat(pyruvateOx.slice(9,20)).concat(krebs.slice(0,10)).concat(krebs.slice(12,19)).concat(ETC).concat(chemiosmosis).concat(netATP),
    currentProcess: 'chemiosmosis',
    question: 'Watch ATP being made as protons diffuse through ATP synthase',
    dragok: false,
    startX: null,
    startY: null,
  },
  // create variable to make chemiosmosis visible
];

export {carbons , processes, reactants, glycolysis, pyruvateOx, krebs, States};
